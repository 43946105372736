import { useState } from "react";

import Form from "../../components/Forms";

const NewProduct = () => {
  const [products, setProducts] = useState([]);

  const newProductRegistered = (product) => {
    console.log(product);
    setProducts([...products, product]);
  };

  return (
    <>
      <Form registeredProducts={(product) => newProductRegistered(product)} />
    </>
  );
};

export default NewProduct;
