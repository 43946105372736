import { useState, useEffect } from "react";
import { Link } from "@aws-amplify/ui-react";
import "./List.css";
import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  ScrollView,
} from "@aws-amplify/ui-react";

import axios from "axios";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import jsonBeautify from "json-beautify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  border: "2px solid rgba(211,211,211,0.9)",
  p: 4,
};

const List = () => {
  const [error, setError] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState("");
  const [openProduct, setOpenProduct] = useState(false);
  const [openExtras, setOpenExtras] = useState(false);
  const handleOpenExtras = () => setOpenExtras(true);
  const handleOpenProduct = () => setOpenProduct(true);
  const handleCloseExtras = () => setOpenExtras(false);
  const handleCloseProduct = () => setOpenProduct(false);

  const url = "https://api.moto-demomode-cloud.com/posts";
  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          setLoaded(true);
          setItems(result);
        },
        (error) => {
          setLoaded(true);
          setError(error);
        }
      );
  }, []);

  const removeData = (item) => {
    if (!item || !item._id) {
      console.log(item._id);
      console.log("Invalid item or _id is missing");
      return;
    }

    fetch("http://localhost:3030/posts/" + item._id, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.ok) {
          console.log("Deleted");
        } else {
          throw new Error("Failed to delete");
        }
      })
      .catch((err) => console.log(err));
  };
  // const removeData = (item) => {
  //   fetch("http://localhost:3030/posts/" + item._id, {
  //     method: "DELETE",
  //   })
  //     .then((response) => {
  //       if (response.ok) {
  //         console.log("Deleted");
  //       } else {
  //         throw new Error("Failed to delete");
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };
  // const removeData = (item) => {
  //   axios
  //     .delete("https://api.moto-demomode-cloud.com/posts/" + item._id)
  //     .then(console.log("Deleted"))
  //     .catch((err) => console.log(err));
  // };

  const data = Object.values(items);

  const search_parameters = Object.keys(Object.assign({}, ...data));
  const filter_items = [...new Set(data.map((item) => item.environment))];

  function search(items) {
    return items.filter(
      (item) =>
        item.environment.includes(filter) &&
        search_parameters.some((parameter) =>
          item[parameter]?.toString().toLowerCase().includes(query)
        )
    );
  }

  function parseJSON(jsonProduct) {
    try {
      const jsonObject = JSON.parse(jsonProduct);
      console.log(jsonObject);
      return <pre>{jsonBeautify(jsonObject, null, 2, 80)}</pre>;
    } catch (error) {
      console.error("JSON ERROR TO ANALYZE:", error);
      return "Invalid JSON or NULL";
    }
  }

  const renderModalContent = (item, contentType) => {
    return (
      <Box sx={style}>
        {contentType === "jsonProduct" ? (
          item.jsonProduct != null ? (
            <p>{parseJSON(item.jsonProduct)}</p>
          ) : (
            <TableCell>''</TableCell>
          )
        ) : contentType === "jsonExtras" ? (
          item.jsonExtras != null ? (
            <p>{parseJSON(item.jsonExtras)}</p>
          ) : (
            <TableCell>''</TableCell>
          )
        ) : null}
      </Box>
    );
  };

  if (error) {
    return <>{error.message}</>;
  } else if (!loaded) {
    return <>loading...</>;
  } else {
    return (
      <div className="tables">
        <div className="flex">
          <h3>List and Update Products</h3>
          <label htmlFor="search-form">
            <input
              type="search"
              name="search-form"
              id="search-form"
              className="search-input"
              placeholder="Search for..."
              onChange={(e) => setQuery(e.target.value)}
            />
          </label>
          <div className="select">
            <span className="sr-only">Search by </span>
            <select
              onChange={(e) => setFilter(e.target.value)}
              className="custom-select"
              aria-label="Filter"
            >
              <option value="">Filter</option>
              {/* {search_parameters.map((item) => ( */}
              {filter_items.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
            <span className="focus"></span>
          </div>
        </div>

        <ScrollView width="100%" height="100%" maxWidth="100%">
          <Table
            caption=""
            highlightOnHover={true}
            size="small"
            variation="striped"
          >
            <TableHead>
              <TableRow>
                <TableCell as="th">Product</TableCell>
                <TableCell as="th">Model</TableCell>
                <TableCell as="th">SKU</TableCell>
                <TableCell as="th">OS</TableCell>
                <TableCell as="th">Carrier</TableCell>
                <TableCell as="th">Environment</TableCell>
                <TableCell as="th">Campaign</TableCell>
                <TableCell as="th">Language</TableCell>
                <TableCell as="th">Json Product</TableCell>
                <TableCell as="th">Json Extras</TableCell>
                <TableCell as="th">Screensaver</TableCell>
                <TableCell as="th">Media</TableCell>
                <TableCell as="th">APK</TableCell>
                <TableCell as="th"></TableCell>
                <TableCell as="th"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {search(data).map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{item.product}</TableCell>
                    <TableCell>{item.model}</TableCell>
                    <TableCell>{item.sku}</TableCell>
                    <TableCell>{item.os}</TableCell>
                    <TableCell>{item.carrier}</TableCell>
                    <TableCell>{item.environment}</TableCell>
                    <TableCell>{item.campaign}</TableCell>
                    <TableCell>{item.locale}</TableCell>
                    <TableCell>
                      <Button onClick={handleOpenProduct}>Product</Button>
                      <Modal
                        open={openProduct}
                        onClose={handleCloseProduct}
                        sx={{
                          "& .MuiBackdrop-root": {
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <Box sx={style}>
                          {renderModalContent(item, "jsonProduct")}
                        </Box>
                      </Modal>
                    </TableCell>
                    <TableCell>
                      <Button onClick={handleOpenExtras}>Extras</Button>
                      <Modal
                        open={openExtras}
                        onClose={handleCloseExtras}
                        sx={{
                          "& .MuiBackdrop-root": {
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        {/* <Box sx={style}> */}
                        {renderModalContent(item, "jsonExtras")}
                        {/* </Box> */}
                      </Modal>
                    </TableCell>
                    {item.screensaver.length > 0 ? (
                      item.screensaver.map((sub, subindex) => (
                        <TableCell key={subindex}>
                          <Link href={sub.urlScreensaver} isExternal={true}>
                            {sub.nameScreensaver}
                          </Link>
                        </TableCell>
                      ))
                    ) : (
                      <TableCell>NULL</TableCell>
                    )}
                    {item.media.length > 0 ? (
                      item.media.map((sub, subindex) => (
                        <TableCell key={subindex}>
                          <Link href={sub.urlMedia} isExternal={true}>
                            {sub.nameMedia}
                          </Link>
                        </TableCell>
                      ))
                    ) : (
                      <TableCell>NULL</TableCell>
                    )}
                    {item.apk.length > 0 ? (
                      item.apk.map((sub, subindex) => (
                        <TableCell key={subindex}>
                          <Link href={sub.urlAPK} isExternal={true}>
                            {sub.nameAPK}
                          </Link>
                        </TableCell>
                      ))
                    ) : (
                      <TableCell>NULL</TableCell>
                    )}
                    <TableCell>
                      <img
                        src="https://img.icons8.com/?size=512&id=86373&format=png"
                        width="20px"
                        height="20px"
                        alt="Edit"
                      />
                    </TableCell>

                    <TableCell>
                      <button onClick={() => removeData(item._id)}>
                        <img
                          src="https://img.icons8.com/?size=512&id=1942&format=png"
                          width="20px"
                          height="20px"
                          alt="Remove"
                        />
                      </button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </ScrollView>
      </div>
    );
  }
};

export default List;
