import styled from "styled-components";

export const Container = styled.div`
  font-size: larger;
  margin-top: 15px;
  h3 {
    text-align: center;
  }
  h2 {
    margin: 10px 0 10px 0;
  }
`;

export const Forms = styled.section`
  display: flex;
  justify-content: center;
  margin: 40px 0;

  .forms {
    font-size: 15px;
    text-align: justify;
    min-width: 70%;
    max-width: 80%;
    background-color: #f2f2f2;
    border-radius: 20px;
    padding: 36px 64px;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    display: block;
  }

  .flex-item {
    justify-content: space-between;
    display: inline-flex;
    width: 100%;
  }

  input#newAPK {
    background-color: #fff;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.06);
    text-align: center;
    align-self: center;
    font-size: 20px;
    padding: 24px;
    box-sizing: border-box;
    width: 100%;
  }

  label {
    font-size: 20px;
  }

  button {
    margin: 20px 0 0 0;
  }
`;
