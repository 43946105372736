import "./style";

import { FaBars } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Navigation } from "./style";

async function signOut() {
  try {
    await Auth.signOut({ global: true });
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

const Header = () => {
  return (
    <Navigation>
      <NavLink className="NavLink" to="/">
        <img className="logo" src="../../images/motorola.png" alt="logo" />
      </NavLink>
      <FaBars className="FaBars" />
      <div className="NavMenu">
        <ul>
          <NavLink className="NavLink" to="/new-product">
            <li>New Product</li>
          </NavLink>
          <NavLink className="NavLink" to="/list-product">
            <li>List Product</li>
          </NavLink>
          <NavLink className="NavLink" to="/new-carrier">
            <li>New Carrier</li>
          </NavLink>
          <NavLink className="NavLink" to="/new-campaign">
            <li>New Campaign</li>
          </NavLink>
          <NavLink className="NavLink" to="/new-assets">
            <li>New Assets</li>
          </NavLink>
          <NavLink className="NavLink" to="/new-core">
            <li>New Core</li>
          </NavLink>
          <NavLink className="NavLink" to="/" onClick={signOut}>
            <li>Logout</li>
          </NavLink>
        </ul>
      </div>
    </Navigation>
  );
};

export default Header;
