import Button from "../Button";
import Dropdown from "../Dropdown";
import InputFiles from "../InputFile";
import TextField from "../TextField";
import { useState } from "react";
import "./form.css";
import axios from "axios";

const Forms = (props) => {
  const carrierSelect = [
    "",
    "Default",
    "Retail Brazil",
    "Claro Brazil",
    "TIM Brazil",
    "Vivo Brazil",
    "Claro Chile",
    "Claro Colombia",
    "Claro Peru",
    "Claro Puerto Rico",
    "Claro Guatemala",
    "Claro El Salvador",
    "Telcel Mexico",
    "Altan Mexico",
    "AT&T Mexico",
    "Retail Mexico",
    "Retail Argentina",
    "Claro Argentina",
    "Entel Chile",
    "Movistar Chile",
    "WOM Chile",
    "Retail El Salvador",
    "Retail Uruguay",
    "Movistar Mexico",
    "Retail Guatemala",
    "Retail Colombia",
  ];

  const environmentSelect = ["", "prod", "debug", "stage"];
  const osSelect = ["", "Default", "10", "11", "12", "13", "14"];

  const campaignSelect = ["", "Default", "VALENTINES", "FATHERS", "MOTHER'S"];
  const localeSelect = [
    "",
    "Default",
    "af",
    "af-ZA",
    "ar",
    "ar-AE",
    "ar-BH",
    "ar-DZ",
    "ar-EG",
    "ar-IQ",
    "az-AZ",
    "es-ES",
    "ar-JO",
    "ar-KW",
    "ar-LB",
    "ar-LY",
    "ar-MA",
    "ar-OM",
    "ar-QA",
    "ar-SA",
    "ar-SY",
    "ar-TN",
    "ar-YE",
    "az",
    "be",
    "be-BY",
    "bg",
    "bg-BG",
    "bs-BA",
    "ca",
    "ca-ES",
    "cs",
    "cs-CZ",
    "cy",
    "cy-GB",
    "da",
    "da-DK",
    "de",
    "de-AT",
    "de-CH",
    "de-DE",
    "de-LI",
    "de-LU",
    "dv",
    "dv-MV",
    "el",
    "el-GR",
    "en",
    "en-AU",
    "en-BZ",
    "en-CA",
    "en-CB",
    "en-GB",
    "en-IE",
    "en-JM",
    "en-NZ",
    "en-PH",
    "en-TT",
    "en-US",
    "en-ZA",
    "en-ZW",
    "eo",
    "es",
    "es-AR",
    "es-BO",
    "es-CL",
    "es-CO",
    "es-CR",
    "es-DO",
    "es-EC",
    "es-GT",
    "es-HN",
    "es-MX",
    "es-NI",
    "es-PA",
    "es-PE",
    "es-PR",
    "es-PY",
    "es-SV",
    "es-UY",
    "es-VE",
    "et",
    "et-EE",
    "eu",
    "eu-ES",
    "fa",
    "fa-IR",
    "fi",
    "fi-FI",
    "fo",
    "fo-FO",
    "fr",
    "fr-BE",
    "fr-CA",
    "fr-CH",
    "fr-FR",
    "fr-LU",
    "fr-MC",
    "gl",
    "gl-ES",
    "gu",
    "gu-IN",
    "he",
    "he-IL",
    "hi",
    "hi-IN",
    "hr",
    "hr-BA",
    "hr-HR",
    "hu",
    "hu-HU",
    "hy",
    "hy-AM",
    "id",
    "id-ID",
    "is",
    "is-IS",
    "it",
    "it-CH",
    "it-IT",
    "ja",
    "ja-JP",
    "ka",
    "ka-GE",
    "kk",
    "kk-KZ",
    "kn",
    "kn-IN",
    "ko",
    "ko-KR",
    "kok",
    "kok-IN",
    "ky",
    "ky-KG",
    "lt",
    "lt-LT",
    "lv",
    "lv-LV",
    "mi",
    "mi-NZ",
    "mk",
    "mk-MK",
    "mn",
    "mn-MN",
    "mr",
    "mr-IN",
    "ms",
    "ms-BN",
    "ms-MY",
    "mt",
    "mt-MT",
    "nb",
    "nb-NO",
    "nl",
    "nl-BE",
    "nl-NL",
    "nn-NO",
    "ns",
    "ns-ZA",
    "pa",
    "pa-IN",
    "pl",
    "pl-PL",
    "ps",
    "ps-AR",
    "pt",
    "pt-BR",
    "pt-PT",
    "qu",
    "qu-BO",
    "qu-EC",
    "qu-PE",
    "ro",
    "ro-RO",
    "ru",
    "ru-RU",
    "sa",
    "sa-IN",
    "se",
    "se-FI",
    "se-NO",
    "se-SE",
    "sk",
    "sk-SK",
    "sl",
    "sl-SI",
    "sq",
    "sq-AL",
    "sr-BA",
    "sr-SP",
    "sv",
    "sv-FI",
    "sv-SE",
    "sw",
    "sw-KE",
    "syr",
    "syr-SY",
    "ta",
    "ta-IN",
    "te",
    "te-IN",
    "th",
    "th-TH",
    "tl",
    "tl-PH",
    "zh-HK",
    "zh-MO",
    "zh-SG",
    "zu",
    "zu-ZA",
    "tn",
    "tn-ZA",
    "tr",
    "tr-TR",
    "tt",
    "tt-RU",
    "ts",
    "uk",
    "uk-UA",
    "ur",
    "ur-PK",
    "uz",
    "uz-UZ",
    "vi",
    "vi-VN",
    "xh",
    "xh-ZA",
    "zh",
    "zh-CN",
    "zh-TW",
  ];

  const [product, setProduct] = useState("");
  const [model, setModel] = useState("");
  const [sku, setSku] = useState("");
  const [os, setOS] = useState("");
  const [imei, setImei] = useState("");
  const [jsonProduct, setJsonProduct] = useState("");
  const [jsonExtras, setJsonExtras] = useState("");
  const [carrier, setCarrier] = useState("");
  const [environment, setEnvironment] = useState("");
  const [campaign, setCampaign] = useState("");
  const [locale, setLanguage] = useState("");
  const [screensaver, setScreensaver] = useState("");
  const [media, setMedia] = useState("");
  const [sizzle, setSizzle] = useState("");

  const save = (event) => {
    console.log("CLICK");
    const url = "https://api.moto-demomode-cloud.com/posts";
    // const url = "http://localhost:3030/posts";
    // event.preventDefault();
    props.registeredProducts({
      product,
      model,
      sku,
      os,
      jsonProduct,
      jsonExtras,
      carrier,
      environment,
      campaign,
      locale,
      imei,
      screensaver,
      media,
      sizzle,
    });
    axios
      .post(
        url,
        {
          product,
          model,
          sku,
          os,
          jsonProduct,
          jsonExtras,
          carrier,
          environment,
          campaign,
          locale,
          imei,
          screensaver,
          media,
          sizzle,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        console.log("Form sended", response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="container">
      <div onSubmit={save} className="forms">
        <h2>Enter product data</h2>

        <TextField
          required={true}
          label="Product"
          placeholder="Enter the Product name"
          valor={product}
          wordChanged={(valor) => setProduct(valor)}
        />
        <TextField
          required={true}
          label="Model"
          placeholder="Enter the name of the Model"
          valor={model}
          wordChanged={(valor) => setModel(valor)}
        />

        <div className="flex-item">
          <TextField
            required={true}
            label="Sku"
            placeholder="Enter the SKU"
            valor={sku}
            wordChanged={(valor) => setSku(valor)}
          />
          <Dropdown
            required={true}
            items={environmentSelect}
            label="Environment"
            valor={environment}
            wordChanged={(valor) => setEnvironment(valor)}
          />
          <Dropdown
            required={true}
            items={osSelect}
            label="OS"
            valor={os}
            wordChanged={(valor) => setOS(valor)}
          />
        </div>
        <Dropdown
          label="Carrier"
          items={carrierSelect}
          valor={carrier}
          required={true}
          wordChanged={(valor) => setCarrier(valor)}
        />
        <Dropdown
          label="Campaign"
          items={campaignSelect}
          valor={campaign}
          required={true}
          wordChanged={(valor) => setCampaign(valor)}
        />
        <Dropdown
          label="Language"
          items={localeSelect}
          valor={locale}
          required={true}
          wordChanged={(valor) => setLanguage(valor)}
        />
        <div className="flex-item">
          <InputFiles
            required={true}
            label="Media"
            id="media"
            setFile={(valor) => setMedia(valor)}
          />
          <InputFiles
            required={true}
            label="Screensaver"
            id="screensaver"
            setFile={(valor) => setScreensaver(valor)}
          />
          <InputFiles
            required={true}
            label="KSP"
            id="sizzle"
            setFile={(valor) => setSizzle(valor)}
          />
        </div>
        <div className="flex-item">
          <TextField
            label="Json Product"
            placeholder="Json Product"
            valor={jsonProduct}
            wordChanged={(valor) => setJsonProduct(valor)}
          />
          <TextField
            label="Json Extras"
            placeholder="Optional"
            valor={jsonExtras}
            wordChanged={(valor) => setJsonExtras(valor)}
          />
          <TextField
            label="IMEI"
            placeholder="Optional"
            valor={imei}
            wordChanged={(valor) => setImei(valor)}
          />
          {/* <InputFiles required={true} label="Ksp" id="ksp" /> */}
        </div>
        <div className="flex-item">
          <InputFiles required={true} label="Extras" id="kspExtras" />
        </div>

        <Button value="Register" onClick={save}>
          Register
        </Button>
      </div>
    </div>
  );
};

export default Forms;
