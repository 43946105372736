import { Container } from "./style";

interface TextFieldProps {
  value: string;
  required?: boolean;
  label: string;
  placeholder: string;
  wordChanged: any;
}

const TextField = ({
  value,
  required,
  label,
  placeholder,
  wordChanged,
}: TextFieldProps) => {
  const updateWrite = (event: React.ChangeEvent<HTMLInputElement>) => {
    wordChanged(event.target.value);
    // console.log(event.target.value)
  };

  // console.log(updateWrite)
  return (
    <Container>
      <label>{label}</label>
      <input
        value={value}
        onChange={updateWrite}
        required={required}
        placeholder={placeholder}
      />
    </Container>
  );
};

export default TextField;
