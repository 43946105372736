import styled from "styled-components";

export const Container = styled.div`
  /* margin: 24px 0; */
  input {
    background-color: #fff;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.06);
    width: 100%;
    border: none;
    font-size: 20px;
    padding: 24px;
    box-sizing: border-box;
    display: inline-block;
  }
  .custom-file-upload {
    width: 350px;
    padding: 0px 5px 0px 0px;
  }
  label {
    display: block;
    margin-bottom: 8px;
    font-size: 20px;
  }
`;
