import styled from "styled-components";

export const Navigation = styled.nav`
  padding: 0px;
  display: flex;
  align-items: center;
  height: 100px;
  max-width: 100%;
  background-color: lightgray;
  justify-content: space-between;
  /* font-size: 10rem; */

  .NavLink {
    color: #fff;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration: none;
    padding: 0px;
    height: 100%;
    cursor: pointer;
  }

  .FaBars {
    display: none;
    color: #fff;
  }

  #signout:hover {
    color: rgba(250, 0, 0, 0.8);

    @media screen and (max-width: 768px) {
      .FaBars {
        display: block;
        right: 0;
        margin-right: 20px;
        font-size: 1.8rem;
        cursor: pointer;
      }
    }
  }

  .NavMenu {
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    .NavMenu {
      display: none;
    }
  }

  ul {
    display: flex;
  }

  li {
    margin-left: 20px;
    margin-right: 40px;
    list-style: none;
  }

  li {
    color: #0f030f;
    text-decoration: none;
  }

  li:hover {
    color: grey;
  }

  .logo {
    height: 80px;
    max-width: 130px;
    margin: 10px;
    margin-left: 20px;
  }
`;
