import { Container } from "./style";

const InputFiles = (props) => {
  function handleFile(event) {
    props.setFile(event.target.files[0]);
    console.log(event.target.files[0]);
  }

  return (
    <Container>
      <label htmlFor="file" className="custom-file-upload">
        {props.label}
        <input
          name="inputFile"
          onChange={handleFile}
          required={props.required}
          type="file"
          id={props.id}
          accept=".zip, .mp4, .jpg, .jpeg, .png, .vnd.android.package-archive"
        />
      </label>
    </Container>
  );
};

export default InputFiles;
