import styled from "styled-components";

export const Container = styled.div`
  margin: 24px 0;
  select {
    background-color: #fff;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.06);
    min-width: 350px;
    min-height: 78px;
    width: 100%;
    border: none;
    font-size: 20px;
    padding: 24px;
    box-sizing: border-box;
  }
  label {
    display: block;
    margin-bottom: 8px;
    font-size: 20px;
  }
`;
