import { ButtonComp } from "./style";

interface ButtonProp {
  value: string;
  active?: boolean;
  onClick?: () => void;
}

const Button = ({ value, active, onClick }: ButtonProp) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <ButtonComp type="submit" onClick={handleClick}>
      {value}
    </ButtonComp>
  );
};

export default Button;
