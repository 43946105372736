import React from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import aws_exports from "./aws-exports";
import { AwsRum } from "aws-rum-web";

import Header from "./components/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ListProduct from "./pages/ListProduct";
import NewAssets from "./pages/NewAssets";
import NewCampaign from "./pages/NewCampaign";
import NewCarrier from "./pages/NewCarrier";
import NewProduct from "./pages/NewProduct";
import Home from "./pages/Home";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Authenticator,
  Heading,
  useAuthenticator,
  View,
  Button,
} from "@aws-amplify/ui-react";
import Core from "./pages/NewCore";

Amplify.configure(aws_exports);

try {
  const config = {
    sessionSampleRate: 1,
    guestRoleArn: process.env.REACT_APP_GUESTROLEARN,
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
    telemetries: ["performance", "errors", "http"],
    allowCookies: true,
    enableXRay: false,
  };

  const APPLICATION_ID = process.env.REACT_APP_APPLICATION_ID;
  const APPLICATION_VERSION = "1.0.0";
  const APPLICATION_REGION = "us-east-1";

  const awsRum = new AwsRum(
    APPLICATION_ID as string,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}
const componentsAuth = {
  SignIn: {
    Header() {
      return (
        <Heading>
          <div className="boxSignIn">
            <img
              className="logoSignIn"
              src="images/motorola.png"
              alt="Logo Motorola"
            />
          </div>
        </Heading>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();
      function handleOnChange(value: string | null) {
        console.log("Captcha value: ", value);
      }

      if (!handleOnChange) {
        console.log("Invalid");
      }

      return (
        <View textAlign="center">
          <div className="boxSignIn">
            <ReCAPTCHA
              sitekey="6LcZTAAmAAAAAPfxdoo8E14r5hvHsQrllFCRvqAG"
              onChange={handleOnChange}
            />
          </div>
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            variation="link"
          >
            Reset Password
          </Button>
        </View>
      );
    },
  },
};

export default function App() {
  return (
    <Authenticator
      socialProviders={["google"]}
      // formFields={formFields}
      components={componentsAuth}
      hideSignUp={true}
    >
      {({ signOut, user }) => (
        <main>
          <div className="App">
            <BrowserRouter>
              <Header />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/new-product" element={<NewProduct />} />
                <Route path="/list-product" element={<ListProduct />} />
                <Route path="/new-carrier" element={<NewCarrier />} />
                <Route path="/new-campaign" element={<NewCampaign />} />
                <Route path="/new-assets" element={<NewAssets />} />
                <Route path="/new-core" element={<Core />} />
              </Routes>
            </BrowserRouter>
          </div>
          {/* <button onClick={signOut}>Sign out</button> */}
        </main>
      )}
    </Authenticator>
  );
}
