import styled from "styled-components";

export const ButtonComp = styled.button`
  background-color: #105566;
  border-radius: 10px;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
  border: none;
  width: 150px;
  height: 70px;
  cursor: pointer;
  margin-top: 1.5rem;
  &:hover {
    opacity: 80%;
  }
`;
